import React from "react";
import "./Home.css";
import Hardware from "../../assets/img/home-page/home-hardware.svg";
import Night from "../../assets/img/home-page/home-night.svg";
import Sensor from "../../assets/img/home-page/home-sensor.svg";
import DualCamera from "../../assets/img/home-page/dual-camera.svg";
import Resolution from "../../assets/img/home-page/resolusi.svg";
import FHD from "../../assets/img/home-page/fhd-camera.png";
import SolarCamera from "../../assets/img/home-page/solar-camera.svg";
import WideCamera from "../../assets/img/home-page/wide-camera.svg";
import Camera4K from "../../assets/img/home-page/4k-camera.png";
import CameraHD from "../../assets/img/home-page/hd-camera.png";
import Wifi from "../../assets/img/home-page/wifi.png";
import dekkaTable from "../../assets/img/footer-dekka.png";
import check from "../../assets/img/home-page/checklist.png";
import cross from "../../assets/img/home-page/crosslist.png";
import FreeMemoryCard from "../../assets/img/home-page/home-memory.svg";
import FreeInstallment from "../../assets/img/home-page/home-free-instalation.svg";
import CustomerCare from "../../assets/img/home-page/home-customer-care.svg";
import LocationBranch from "../../assets/img/home-page/home-location.svg";
import Warranty from "../../assets/img/home-page/home-warranty.svg";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import SendMessage from "../../components/SendMessage";
import { Helmet } from "react-helmet";
import DoubleParkingFeature from "../../components/DoubleParkingFeature";

const Home = () => {
  const linkSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M22 3H29V10M27.5 4.5L20 12M17 5H8C7.20435 5 6.44129 5.31607 5.87868 5.87868C5.31607 6.44129 5 7.20435 5 8V24C5 24.7956 5.31607 25.5587 5.87868 26.1213C6.44129 26.6839 7.20435 27 8 27H24C24.7956 27 25.5587 26.6839 26.1213 26.1213C26.6839 25.5587 27 24.7956 27 24V15" stroke="#ECF6FB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;
  return (
    <>
      <Helmet>
        <title>DEKKA - Always On Dashcam</title>
        <meta
          name="description"
          content="Dashcam DEKKA - Merekam perjalanan dan kejadian pentingmu 24 jam. Terdapat beberapa tipe yang bisa kamu pilih sesuai dengan kebutuhanmu."
        />
      </Helmet>
      <div className="home-parent">
        <div className="home-hero-gradient">
          <div className="home-hero">
            <Fade left>
              <div className="home-content">
                <h1>Always on Dashcam DEKKA</h1>
                <p>
                  Merekam perjalanan dan kejadian pentingmu 24 jam non-stop.
                </p>
                <div className="home-hero-button">
                  <a
                    className="home-hero-btn-ss"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://superspring.co.id"
                  >
                    Super Spring
                    <div dangerouslySetInnerHTML={{ __html: linkSVG }}></div>
                  </a>
                  <a href="#include-section" className="buy-dekka-btn btn">
                    <span>Pelajari Lebih Lanjut</span>
                  </a>
                </div>
              </div>
            </Fade>
            <Fade duration={4000}>
              <div className="home-hero-img">
                <img src="/hero.png" alt="Hero" />
              </div>
            </Fade>
          </div>
        </div>
        <div id="include-section" className="home-include">
          <div className="include-title text-center d-flex flex-column gap-3">
            <h1>
              <span>Complete Solutions,</span>
              <br />
              Bebas Biaya Tersembunyi
            </h1>
            <p>Setiap Pembelian DEKKA sudah termasuk :</p>
          </div>
          <div className="include-items">
            <div className="include-items-group">
              <Fade>
                <div className="include-item">
                  <div>
                    <img src={FreeInstallment} alt="Free Installment Icon" />
                  </div>
                  <h2 className="inclue-item-title">Gratis Biaya Pemasangan</h2>
                  <p className="include-item-caption">
                    Pemasangan di rumah maupun di 49+ lokasi cabang SUPERSPRING.
                  </p>
                </div>
              </Fade>
              <div className="include-separator"></div>
              <Fade>
                <div className="include-item">
                  <div>
                    <img src={Hardware} alt="HardWare Icon" />
                  </div>
                  <h2 className="inclue-item-title">Gratis Hardware Kit</h2>
                  <p className="include-item-caption">
                    Memastikan DEKKA aktif merekam 24/7 termasuk saat kendaraan
                    terparkir.
                  </p>
                </div>
              </Fade>
              <div className="include-separator"></div>
              <Fade>
                <div className="include-item">
                  <div>
                    <img src={FreeMemoryCard} alt="Free Memory Card Icon" />
                  </div>
                  <h2 className="inclue-item-title">
                    Gratis Memory Card 64 & 128 GB
                  </h2>
                  <p className="include-item-caption">
                    Berkualitas, original dan cocok digunakan untuk dashcam
                    DEKKA. *128GB untuk DK40.
                  </p>
                </div>
              </Fade>
            </div>
            <div className="include-separator-bottom"></div>
            <div className="include-items-group">
              <Fade>
                <div className="include-item">
                  <div>
                    <img src={Warranty} alt="Warranty Icon" />
                  </div>
                  <h2 className="inclue-item-title">Garansi 2 Tahun</h2>
                  <p className="include-item-caption">
                    Unit rusak langsung ganti baru.
                  </p>
                </div>
              </Fade>
              <div className="include-separator"></div>
              <Fade>
                <div className="include-item">
                  <div>
                    <img src={CustomerCare} alt="Customer Care Icon" />
                  </div>
                  <h2 className="inclue-item-title">Customer Care 24 Jam</h2>
                  <p className="include-item-caption">
                    Dukungan pelanggan yang fast response dan solutif 24/7.
                  </p>
                </div>
              </Fade>
              <div className="include-separator"></div>
              <Fade>
                <div className="include-item">
                  <div>
                    <img src={LocationBranch} alt="Location Branch Icon" />
                  </div>
                  <h2 className="inclue-item-title">49+ Lokasi Cabang</h2>
                  <p className="include-item-caption">
                    Jaringan kantor cabang tersebar di seluruh Indonesia.
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <div className="home-features">
          <div className="features-title text-center d-flex flex-column gap-3">
            <h1>Semua Dashcam DEKKA dilengkapi Fitur</h1>
          </div>
          <div className="features-card-container">
            <div className="features-left">
              <Fade left>
                <div className="features-card">
                  <video width="100%" autoPlay loop muted>
                    <source
                      src="https://img.gps.id/dekka/home/night-vision.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </Fade>
            </div>
            <div className="features-right">
              <Fade right>
                <div className="features-card">
                  <div className="desc-wrapper">
                    <img src={Night} width={60} alt="Night Vision Icon" />
                    <div className="features-desc">
                      <h4>Night Vision</h4>
                      <p>
                        Memasitkan hasil rekaman akan tetap jernih saat malam
                        hari.
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <div className="features-card-container">
            <div className="features-left">
              <Fade left>
                <div className="features-card">
                  <div className="desc-wrapper">
                    <img src={Sensor} width={60} alt="G-Sensor Icon" />
                    <div className="features-desc">
                      <h4>G-Sensor</h4>
                      <p>
                        Otomatis merekam saat terjadi guncangan tanpa harus
                        khawatir file terhapus.
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="features-right">
              <Fade right>
                <div className="features-card">
                  <video width="100%" autoPlay loop muted>
                    <source
                      src="https://img.gps.id/dekka/detail/gsensor.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </Fade>
            </div>
          </div>
          <DoubleParkingFeature />
        </div>
        <div className="home-dashcam">
          <div className="dashcam-title text-center d-flex flex-column gap-3">
            <h1>Pilih DEKKA Sesuai Kebutuhanmu</h1>
            <p>
              Hadir dalam beberap tipe dengan beragam fitur canggih dan lengkap
            </p>
          </div>
          <div className="dk">
            <div className="dk-left">
              <h1>DEKKA DK40</h1>
              <h2>Rp2.699.000</h2>
              <img src="/product/DK40.png" alt="Dekka 40" />
            </div>
            <Fade>
              <div className="dk-right">
                <div className="row">
                  <div className="dk-desc col-6">
                    <img src={Camera4K} alt="Resolution Icon" />
                    <h5>Kamera Depan Resolusi 4K</h5>
                    <p>
                      Menghasilkan rekaman yang jernih dan lebih mendetail
                      resolusi 2160P.
                    </p>
                  </div>
                  <div className="dk-desc col-6">
                    <img src={FHD} alt="Resolution Icon" />
                    <h5>Kamera Belakang Resolusi 1080P</h5>
                    <p>
                      Menghasilkan rekaman yang jernih dan lebih mendetail
                      resolusi 1080P.
                    </p>
                  </div>
                  <div className="dk-desc col-6">
                    <img
                      src={DualCamera}
                      alt="Dual Camera Icon"
                      className="dual-camera"
                    />
                    <h5>Dual Camera</h5>
                    <p>
                      Merekam dari dua kamera sekaligus, depan dan belakang.
                    </p>
                  </div>
                  <div className="dk-desc col-6">
                    <img src={WideCamera} alt="Wide Camera Icon" />
                    <h5>Wide Camera 140°</h5>
                    <p>Cakupan area yang terekam lebih luas dan jelas.</p>
                  </div>
                  <div className="dk-desc col-6">
                    <img src={SolarCamera} alt="Solar Camera Icon" />
                    <h5>GPS</h5>
                    <p>Melihat lokasi video dimana kejadian terekam.</p>
                  </div>
                  <div className="dk-desc col-6">
                    <img src={Wifi} alt="Wide Camera Icon" />
                    <h5>Wifi 5 GHz</h5>
                    <p>
                      Memudahkan transfer file rekaman dari unit dashcam ke
                      handphone.
                    </p>
                  </div>
                  <div className="dk-desc-btn col-lg-8 col-12">
                    <Link
                      to="/product/dk40"
                      className="dk-btn-pelajari btn btn-dekstop"
                    >
                      Lihat Fitur Selengkapnya
                    </Link>
                    <Link
                      to="/product/dk40"
                      className="dk-btn-pelajari btn btn-mobile"
                    >
                      Pelajari
                    </Link>
                    <a
                      href="https://www.tokopedia.com/supersprings/dashcam-dekka-dk40-4k-dual-camera-hardwire-memory-included"
                      target="_blank"
                      className="dk-btn-beli btn"
                      rel="noreferrer"
                    >
                      <span>Beli DK40</span>
                      <div dangerouslySetInnerHTML={{ __html: linkSVG }}></div>
                    </a>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
          <div className="dk">
            <div className="dk-left">
              <h1>DEKKA DK20</h1>
              <h2>Rp2.199.000</h2>
              <img
                src="https://img.gps.id/dekka/home/DK20.png"
                alt="Dekka 20"
              />
            </div>
            <Fade>
              <div className="dk-right">
                <div className="row">
                  <div className="dk-desc col-6">
                    <img src={Resolution} alt="Resolution Icon" />
                    <h5>Kamera Depan Resolusi 2K</h5>
                    <p>
                      Menghasilkan rekaman yang jernih dan lebih mendetail
                      resolusi 1440p.
                    </p>
                  </div>
                  <div className="dk-desc col-6">
                    <img src={CameraHD} alt="Resolution Icon" />
                    <h5>Kamera Belakang Resolusi 720P</h5>
                    <p>
                      Menghasilkan rekaman yang jernih dengan resolusi 720P.
                    </p>
                  </div>
                  <div className="dk-desc col-6">
                    <img
                      src={DualCamera}
                      alt="Dual Camera Icon"
                      className="dual-camera"
                    />
                    <h5>Dual Camera</h5>
                    <p>
                      Merekam dari dua kamera sekaligus, depan dan belakang.
                    </p>
                  </div>
                  <div className="dk-desc col-6">
                    <img src={WideCamera} alt="Wide Camera Icon" />
                    <h5>Wide Camera 140°</h5>
                    <p>Cakupan area yang terekam lebih luas dan jelas.</p>
                  </div>
                  <div className="dk-desc col-6">
                    <img src={SolarCamera} alt="Solar Camera Icon" />
                    <h5>GPS</h5>
                    <p>Melihat lokasi video dimana kejadian terekam.</p>
                  </div>
                  <div className="dk-desc col-6">
                    <img src={Wifi} alt="Wide Camera Icon" />
                    <h5>Wifi 2,4 GHz</h5>
                    <p>
                      Memudahkan transfer file rekaman dari unit dashcam ke
                      handphone.
                    </p>
                  </div>
                  <div className="dk-desc-btn col-lg-8 col-12">
                    <Link
                      to="/product/dk20"
                      className="dk-btn-pelajari btn btn-dekstop"
                    >
                      Lihat Fitur Selengkapnya
                    </Link>
                    <Link
                      to="/product/dk20"
                      className="dk-btn-pelajari btn btn-mobile"
                    >
                      Pelajari
                    </Link>
                    <a
                      href="https://www.tokopedia.com/supersprings/dashcam-dekka-dk20-2k-dual-camera-hardwire-memory-included?extParam=src%3Dshop%26whid%3D1156028"
                      target="_blank"
                      className="dk-btn-beli btn"
                      rel="noreferrer"
                    >
                      <span>Beli DK20</span>
                      <div dangerouslySetInnerHTML={{ __html: linkSVG }}></div>
                    </a>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
          <div className="dk">
            <div className="dk-left">
              <h1>DEKKA DK10</h1>
              <h2>Rp1.099.000</h2>
              <img src="https://img.gps.id/dekka/home/DK10.png" alt="DK10" />
            </div>
            <Fade>
              <div className="dk-right">
                <div className="row">
                  <div className="dk-desc col-6">
                    <img src={FHD} alt="FHD Icon" />
                    <h5>Resolusi Full HD</h5>
                    <p>
                      Menghasilkan rekaman yang jernih dengan resolusi kamera
                      1080p.
                    </p>
                  </div>
                  <div className="dk-desc col-6">
                    <img src={WideCamera} alt="Wide Camera Icon" />
                    <h5>Wide Camera 131°</h5>
                    <p>Cakupan area yang terekam lebih luas dan jelas.</p>
                  </div>
                  <div className="dk-desc-btn col-lg-8 col-12">
                    <Link
                      to="/product/dk10"
                      className="dk-btn-pelajari btn btn-dekstop"
                    >
                      Lihat Fitur Selengkapnya
                    </Link>
                    <Link
                      to="/product/dk20"
                      className="dk-btn-pelajari btn btn-mobile"
                    >
                      Pelajari
                    </Link>
                    <a
                      href="https://www.tokopedia.com/supersprings/dashcam-dekka-dk10-full-hd-camera-hardwire-memory-included?extParam=src%3Dshop%26whid%3D1156028"
                      target="_blank"
                      className="dk-btn-beli btn"
                      rel="noreferrer"
                    >
                      <span>Beli DK10</span>
                      <div dangerouslySetInnerHTML={{ __html: linkSVG }}></div>
                    </a>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <div className="home-table">
          <div className="table-header row">
            <div className="col-lg-3 col-6">
              <img src={dekkaTable} alt="Dekka Logo" />
            </div>
            <div className="col-lg-3 col-2">
              <img
                src="https://img.gps.id/dekka/home/DK10-table.png"
                alt="Dk 10"
              />
              <p>DK10</p>
            </div>
            <div className="col-lg-3 col-2">
              <img
                src="https://img.gps.id/dekka/home/DK20-table.png"
                alt="Dk 20"
              />
              <p>DK20</p>
            </div>
            <div className="col-lg-3 col-2">
              <img src="product/DK40.png" alt="Dk 40" />
              <p>DK40</p>
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>Wifi</span>
            </div>
            <div className="col-lg-3 col-2 | wifi-column">
              <img src={check} alt="Check Icon" />
              <p style={{ margin: 0 }}>2,4 GHz</p>
            </div>
            <div className="col-lg-3 col-2 | wifi-column">
              <img src={check} alt="Check Icon" />
              <p style={{ margin: 0 }}>2,4 GHz</p>
            </div>
            <div className="col-lg-3 col-2 | wifi-column">
              <img src={check} alt="Check Icon" />
              <p style={{ margin: 0 }}>5 GHz</p>
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>Hardware Kit</span>
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>Parking Mode</span>
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>G-Sensor</span>
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>Night Vision</span>
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>Microphone</span>
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>Speaker</span>
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>Loop Recording</span>
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>0.96" Screen</span>
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>GPS</span>
            </div>
            <div className="col-lg-3 col-2">
              <img src={cross} alt="Cross Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>Dual Camera</span>
            </div>
            <div className="col-lg-3 col-2">
              <img src={cross} alt="Cross Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>Wide Camera</span>
            </div>
            <div className="col-lg-3 col-2">
              <span>131°</span>
            </div>
            <div className="col-lg-3 col-2">
              <span>140°</span>
            </div>
            <div className="col-lg-3 col-2">
              <span>140°</span>
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>Resolusi Kamera Depan</span>
            </div>
            <div className="col-lg-3 col-2">
              <span>
                Full HD <br /> 1920 x 1080 P
              </span>
            </div>
            <div className="col-lg-3 col-2">
              <span>
                2K <br /> 2560 x 1440 P
              </span>
            </div>
            <div className="col-lg-3 col-2">
              <span>
                4K <br /> 3840 x 2160 P
              </span>
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>Resolusi Kamera Belakang</span>
            </div>
            <div className="col-lg-3 col-2">
              <img src={cross} alt="Cross Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <span>
                HD <br /> 720p
              </span>
            </div>
            <div className="col-lg-3 col-2">
              <span>
                Full HD <br /> 1080p
              </span>
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>Garansi 2 Tahun</span>
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
          </div>
          <div className="table-content row">
            <div className="col-lg-3 col-6">
              <span>Gratis Biaya Pemasangan</span>
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
            <div className="col-lg-3 col-2">
              <img src={check} alt="Check Icon" />
            </div>
          </div>
        </div>
        <SendMessage />
        <div className="slide-up-section">
          <button
            onClick={() => window.scrollTo(0, 0)}
            className="slide-up-button"
          >
            <img src="/arrow-up.png" alt="Arrow Up Icon" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Home;
